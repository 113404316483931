// Generated by Framer (4b8bcae)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["JTCFn9V8T"];

const serializationHash = "framer-QAeWQ"

const variantClassNames = {JTCFn9V8T: "framer-v-1ghednx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "JTCFn9V8T", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ghednx", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"JTCFn9V8T"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-2358d5f3-5f5b-4cd9-89c4-e082bd2a2a0a, rgb(245, 255, 255))", borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24, ...style}}><ComponentViewportProvider ><motion.div className={"framer-1x013e-container"} layoutDependency={layoutDependency} layoutId={"EGigZnBev-container"}><Phosphor color={"var(--token-64549ac4-d685-4363-a417-5961f1d256e9, rgb(2, 146, 151))"} height={"100%"} iconSearch={"House"} iconSelection={"Check"} id={"EGigZnBev"} layoutId={"EGigZnBev"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QAeWQ.framer-9f5rni, .framer-QAeWQ .framer-9f5rni { display: block; }", ".framer-QAeWQ.framer-1ghednx { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 19px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 19px; will-change: var(--framer-will-change-override, transform); }", ".framer-QAeWQ .framer-1x013e-container { flex: none; height: 15px; position: relative; width: 15px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QAeWQ.framer-1ghednx { gap: 0px; } .framer-QAeWQ.framer-1ghednx > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-QAeWQ.framer-1ghednx > :first-child { margin-left: 0px; } .framer-QAeWQ.framer-1ghednx > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 19
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerB4ULFnWm1: React.ComponentType<Props> = withCSS(Component, css, "framer-QAeWQ") as typeof Component;
export default FramerB4ULFnWm1;

FramerB4ULFnWm1.displayName = "Check Icon";

FramerB4ULFnWm1.defaultProps = {height: 19, width: 19};

addFonts(FramerB4ULFnWm1, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})